export const SET_OFFER_SELECTED = "SET_OFFER_SELECTED";
export const SET_POLICY_SELECTED = "SET_POLICY_SELECTED";
export const SET_APPEAL_REASON = "SET_APPEAL_REASON";
export const SET_APPEAL_REASON_MSG = "SET_APPEAL_REASON_MSG";
export const SET_TYPE_VALUE = "SET_TYPE_VALUE";
export const SET_TYPE_VALUE_MSG = "SET_TYPE_VALUE_MSG";
export const SET_SEND_DIALOG_VISIBLE = "SET_SEND_DIALOG_VISIBLE";
export const SET_LOADING_DIALOG_VISIBLE = "SET_LOADING_DIALOG_VISIBLE";
export const ADD_TRAY_NOTIFICATION = "ADD_TRAY_NOTIFICATION";
export const REMOVE_TRAY_NOTIFICATION = "REMOVE_TRAY_NOTIFICATION";
export const SET_POLICY_VALIDATION_MSG = "SET_POLICY_VALIDATION_MSG";
export const SET_TYPE_VALIDATION_MSG = "SET_TYPE_VALIDATION_MSG";
