import "../scss/index.scss";
import React from "react";
import {connect} from "react-redux";
import {getAction, isNotEmpty} from "../utils/Utils";
import * as types from "../constants/ActionType";
import Photo from "./Photo";
import PriceBlock from "./PriceBlock";
import ProfileBlock from "./ProfileBlock";
import {BrowserRouter} from "react-router-dom";
import LoadingDialog from "./LoadingDialog";
import SendDialog from "./SendDialog";
import TrayDialog from "./TrayDialog";
import {TypeValue} from "../constants/TypeValue";
import Checkbox from "react-custom-checkbox";

class App extends React.Component {

    render() {
        return (
            <BrowserRouter>

                <div className="container">

                    <p className="notification-text">
                        Онлайн встреча является информационной услугой и не заменяет очного приема врача
                    </p>

                    <div className="info-block">
                        <Photo/>
                        <PriceBlock/>
                        <ProfileBlock/>
                    </div>

                    <div className="mb_20">
                        {this.createCheckBox("offerId",
                            this.createOfferText("offerId"),
                            this.props.app.isOfferSelected,
                            value => this.setOfferSelected(value))}
                        <div style={{marginBottom: "10px"}}/>
                        {this.createCheckBox("policyId",
                            this.createPolicyText("policyId"),
                            this.props.app.isPolicySelected,
                            value => this.setPolicySelected(value))}
                    </div>

                    {isNotEmpty(this.props.app.policyValidationMsg) &&
                        <p className="validation-text">{this.props.app.policyValidationMsg}</p>}

                    <div className="button-row">
                        {this.createButton("Написать в&nbsp;Telegram", TypeValue.TELEGRAM)}
                        {this.createButton("Написать на&nbsp;почту", TypeValue.EMAIL)}
                    </div>
                </div>

                <SendDialog/>
                <LoadingDialog/>
                <TrayDialog/>

            </BrowserRouter>
        )
    };

    setOfferSelected(value) {
        if (value && this.props.app.isPolicySelected) {
            this.props.setPolicyValidationMsg(undefined);
        }
        return this.props.setOfferSelected(value);
    }

    setPolicySelected(value) {
        if (value && this.props.app.isOfferSelected) {
            this.props.setPolicyValidationMsg(undefined);
        }
        return this.props.setPolicySelected(value);
    }

    createCheckBox(id, component, isChecked, setSelected) {
        return (
            <div className="d_flex ai-center mb_5">
                <Checkbox
                    id={id}
                    checked={isChecked}
                    borderRadius={2}
                    size={16}
                    icon={<img src="/imgs/check.png" className="checkbox" alt=""/>}
                    style={{marginRight: "10px", backgroundColor: "white", border: "none"}}
                    onChange={value => setSelected(value)}
                />
                {component}
            </div>
        );
    }

    createButton(text, typeValue) {
        return (
            <button dangerouslySetInnerHTML={{__html: text}}
                    className="button"
                    onClick={() => this.showSendDialog(typeValue)}/>
        )
    }

    showSendDialog(typeValue) {
        let validationMsg = undefined;
        if (this.props.app.isOfferSelected && this.props.app.isPolicySelected) {
            if (this.props.app.typeValue !== typeValue) {
                this.props.setTypeValueMsg("");
            }
            this.props.setTypeValidationMsg("");
            this.props.setTypeValue(typeValue);
            this.props.showSendDialog();
        } else {
            validationMsg = "Должны быть проставлены все галки";
        }
        this.props.setPolicyValidationMsg(validationMsg);
    }

    createOfferText(id) {
        return (
            <label className="fs_14" htmlFor={id}>
                Соглашаюсь с {this.createLink("/docs/contract.docx", "договором оферты")} и
                даю {this.createLink("/docs/approval.docx", "согласие")} на обработку персональных данных *
            </label>
        )
    }

    createPolicyText(id) {
        return (
            <label className="fs_14" htmlFor={id}>
                Согласен с {this.createLink("/docs/policy.docx", "политикой")} обработки персональных данных *
            </label>
        )
    }

    createLink(path, value) {
        return <a className="link" href={path}>{value}</a>
    }
}

const initMapStateToProps = state => ({
    app: state.app
});

const initMapDispatchToProps = dispatch => ({
    setOfferSelected: value => dispatch(getAction(types.SET_OFFER_SELECTED, value)),
    setPolicySelected: value => dispatch(getAction(types.SET_POLICY_SELECTED, value)),
    setPolicyValidationMsg: value => dispatch(getAction(types.SET_POLICY_VALIDATION_MSG, value)),
    showSendDialog: () => dispatch(getAction(types.SET_SEND_DIALOG_VISIBLE, true)),
    setTypeValue: value => dispatch(getAction(types.SET_TYPE_VALUE, value)),
    setTypeValueMsg: value => dispatch(getAction(types.SET_TYPE_VALUE_MSG, value)),
    setTypeValidationMsg: value => dispatch(getAction(types.SET_TYPE_VALIDATION_MSG, value))
});

export default connect(
    initMapStateToProps,
    initMapDispatchToProps
)(App);