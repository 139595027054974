import React from "react";
import {connect} from "react-redux";
import {getAction, isEmpty, isNotEmpty} from "../utils/Utils";
import * as types from "../constants/ActionType";
import {AppealReason} from "../constants/AppealReason";
import {send} from "../actions/AppAction";
import {validateEmail, validatePhone} from "../utils/ValidationUtils";
import {TypeValue} from "../constants/TypeValue";

class SendDialog extends React.Component {

    render() {
        if (!this.props.isSendDialogActive) {
            document.body.classList.remove("lock");
            return null;
        }
        document.body.classList.add("lock");

        return (
            <div className="dialog__shadow">
                <div className="dialog">

                    <img className="dialog-close"
                         src="/imgs/close.png"
                         alt=""
                         onClick={this.props.close}/>

                    <div className="radio-block">
                        {this.createRadioGroup(AppealReason, this.props.appealReason, value => this.props.setAppealReason(value))}
                    </div>

                    <textarea value={this.props.appealReasonMsg}
                              className={"text-area"}
                              maxLength={500}
                              rows="5"
                              placeholder="Описание проблемы"
                              onChange={event => this.props.setAppealReasonMsg(event.target.value)}/>

                    <div className="radio-block">
                        {this.createRadioGroup(TypeValue, this.props.typeValue, value => this.setTypeValue(value))}
                    </div>

                    {isNotEmpty(this.props.typeValidationMsg) &&
                        <p className="dialog-validation-text">{this.props.typeValidationMsg}</p>}

                    <div className="send-row">
                        <input value={this.props.typeValueMsg}
                                      maxLength={18}
                                      className="text-field"
                                      placeholder={this.props.typeValue.placeholder}
                                      onChange={event => this.props.setTypeValueMsg(event.target.value)}/>

                        <button dangerouslySetInnerHTML={{__html: "Отправить"}}
                                className="send-button"
                                onClick={() => this.send()}/>
                    </div>

                </div>
            </div>
        );
    }

    setTypeValue(value) {
        if (isNotEmpty(this.props.typeValidationMsg)) {
            this.props.setTypeValidationMsg(undefined);
        }
        this.props.setTypeValue(value);
        this.props.setTypeValueMsg("");
    }

    send() {
        let validationMsg;
        if (TypeValue.TELEGRAM === this.props.typeValue) {
            validationMsg = validatePhone(this.props.typeValueMsg);
        } else if (TypeValue.EMAIL === this.props.typeValue) {
            validationMsg = validateEmail(this.props.typeValueMsg);
        }
        this.props.setTypeValidationMsg(validationMsg);

        if (isEmpty(validationMsg)) {
            this.props.send(this.props.appealReason, this.props.appealReasonMsg, this.props.typeValue, this.props.typeValueMsg)
        }
    }

    createRadioGroup(enumDict, value, func) {
        return Object.values(enumDict).map((enumValue, i) => {
            const isActive = value === enumValue;

            return (
                <div className="appeal-reason-radio" key={i}>
                    <input value={enumValue}
                           id={enumValue.text}
                           name={enumValue.text}
                           type="radio"
                           checked={isActive}
                           onChange={() => func(enumValue)}/>
                    <label htmlFor={enumValue.text}>
                        {enumValue.text}
                    </label>
                </div>
            )
        });
    }
}

const initMapStateToProps = state => ({
    isSendDialogActive: state.app.isSendDialogActive,
    appealReason: state.app.appealReason,
    appealReasonMsg: state.app.appealReasonMsg,
    typeValidationMsg: state.app.typeValidationMsg,
    typeValue: state.app.typeValue,
    typeValueMsg: state.app.typeValueMsg
});

const initMapDispatchToProps = dispatch => ({
    setAppealReason: value => dispatch(getAction(types.SET_APPEAL_REASON, value)),
    setAppealReasonMsg: value => dispatch(getAction(types.SET_APPEAL_REASON_MSG, value)),
    setTypeValue: value => dispatch(getAction(types.SET_TYPE_VALUE, value)),
    setTypeValueMsg: value => dispatch(getAction(types.SET_TYPE_VALUE_MSG, value)),
    setTypeValidationMsg: value => dispatch(getAction(types.SET_TYPE_VALIDATION_MSG, value)),
    send: (appealReason, appealReasonMsg, type, typeValue) => dispatch(send(appealReason, appealReasonMsg, type, typeValue)),
    close: () => dispatch(getAction(types.SET_SEND_DIALOG_VISIBLE, false))
});

export default connect(
    initMapStateToProps,
    initMapDispatchToProps
)(SendDialog);