import {getAction} from "../utils/Utils";
import * as types from "../constants/ActionType";

const NOTIFICATION_DURATION = 3000;
let notificationId = 1;

export function addTrayMessage(message, isError) {
    const notification = {
        id: notificationId,
        message: message,
        error: isError
    };

    return (dispatch) => {
        dispatch(getAction(types.ADD_TRAY_NOTIFICATION, notification));
        setTimeout(() => dispatch(getAction(types.REMOVE_TRAY_NOTIFICATION, notification.id)), NOTIFICATION_DURATION);
        notificationId++
    }
}