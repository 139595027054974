import * as types from "../constants/ActionType";
import {getAction} from "../utils/Utils";
import axios from "axios";
import {addTrayMessage} from "./TrayDialogAction";

export function send(appealReason, appealReasonMsg, type, typeValue) {
    return (dispatch) => {
        dispatch(getAction(types.SET_LOADING_DIALOG_VISIBLE, true));

        axios.post("/api/sendMessage.php", {
            "appealReason": appealReason.text,
            "appealReasonMsg": appealReasonMsg,
            "type": type.text,
            "typeValue": typeValue
        })
            .then(() => {
                dispatch(getAction(types.SET_SEND_DIALOG_VISIBLE, false));
                dispatch(addTrayMessage("Сообщение успешно отправлено", false));
            })
            .catch(() => {
                dispatch(addTrayMessage("Ошибка при отправке сообщения", true));
            })
            .finally(() => {
                dispatch(getAction(types.SET_LOADING_DIALOG_VISIBLE, false));
            });
    }
}