import React from "react";
import {connect} from "react-redux";

class TrayDialog extends React.Component {

    render() {
        if (this.props.notifications.length === 0) {
            return null;
        }

        return (
            <div className="tray-dialog">

                {this.props.notifications.map(notification => {
                    const styles = notification.error ? "tray-dialog-item error" : "tray-dialog-item";
                    return (
                        <div key={notification.id} className={styles}>
                            {notification.message}
                        </div>
                    );
                })}

            </div>
        );
    }
}

const initMapStateToProps = state => ({
    notifications: state.app.notifications
});

export default connect(
    initMapStateToProps
)(TrayDialog);