export const getAction = (type, payload) => {
    return {
        type: type,
        payload: payload
    }
};

export const isEmpty = value => value === undefined || value === null || value === false || value.length === 0
    || (typeof value === "number" && isNaN(value));

export const isNotEmpty = value => !isEmpty(value);