import * as types from "../constants/ActionType";
import {AppealReason} from "../constants/AppealReason";
import {TypeValue} from "../constants/TypeValue";

const initState = {
    isOfferSelected: false,
    isPolicySelected: false,
    appealReason: AppealReason.FIRST,
    appealReasonMsg: "",
    typeValue: TypeValue.EMAIL,
    typeValueMsg: "",

    isLoadingDialogActive: false,
    isSendDialogActive: false,

    policyValidationMsg: "",
    typeValidationMsg: "",
    notifications: []
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case types.SET_OFFER_SELECTED:
            return {
                ...state,
                isOfferSelected: action.payload
            }
        case types.SET_POLICY_SELECTED:
            return {
                ...state,
                isPolicySelected: action.payload
            }
        case types.SET_APPEAL_REASON:
            return {
                ...state,
                appealReason: action.payload
            };
        case types.SET_APPEAL_REASON_MSG:
            return {
                ...state,
                appealReasonMsg: action.payload
            };
        case types.SET_TYPE_VALUE:
            return {
                ...state,
                typeValue: action.payload
            }
        case types.SET_TYPE_VALUE_MSG:
            return {
                ...state,
                typeValueMsg: action.payload.trim()
            }
        case types.SET_SEND_DIALOG_VISIBLE:
            return {
                ...state,
                isSendDialogActive: action.payload
            }
        case types.SET_LOADING_DIALOG_VISIBLE:
            return {
                ...state,
                isLoadingDialogActive: action.payload
            }

        case types.SET_POLICY_VALIDATION_MSG:
            return {
                ...state,
                policyValidationMsg: action.payload
            }

        case types.SET_TYPE_VALIDATION_MSG:
            return {
                ...state,
                typeValidationMsg: action.payload
            }

        case types.ADD_TRAY_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    action.payload
                ]
            }
        case types.REMOVE_TRAY_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== action.payload)
            }

        default:
            return state;
    }
}