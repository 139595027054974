import appReducer from "./reducers/appReducer";
import {configureStore} from "@reduxjs/toolkit";

const store = configureStore({
    reducer: {
        app: appReducer
    },
    devTools: true
})

export default store;