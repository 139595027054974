import React from "react";
import {AppealReason} from "../constants/AppealReason";

export default class PriceBlock extends React.Component {

    render() {
        return (
            <div className="price-block">
                <p className="mb_5"><b>Стоимость</b></p>
                {this.createPriceRow("Первичная онлайн встреча", AppealReason.FIRST.price)}
                {this.createPriceRow("Повторная онлайн встреча", AppealReason.SECOND.price)}
                {this.createPriceRow("Составление индивидуального графика вакцинации", AppealReason.VACCINE.price)}
            </div>
        )
    }

    createPriceRow(title, price) {
        return (
            <div className="price-row">
                <p >
                    {title}
                </p>
                <p >
                    {price + "\u00A0руб."}
                </p>
            </div>)
    }
}
