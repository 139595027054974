export const TypeValue = {
    TELEGRAM: {
        id: 1,
        text: "Telegram",
        placeholder: "Телефон"
    },
    EMAIL: {
        id: 2,
        text: "Email",
        placeholder: "Email"
    }
}