import {isEmpty} from "./Utils";

export const validatePhone = value => {
    const PHONE_ERROR_MESSAGE = "Телефон заполнен некорректно";

    if (value.length === 0) {
        return "Телефон не заполнен";
    } else if (value.length < 10) {
        return PHONE_ERROR_MESSAGE;
    }
    return "";
};

export const validateEmpty = (value, message) => {
    if (value.length === 0) {
        return isEmpty(message) ? "Поле не заполнено" : message;
    }
    return "";
};

export const validateTelegram = value => {
    const regexp = /[^\w]/;

    if (isEmpty(value)) {
        return "Ник не заполнен";
    } else if (!value.startsWith("@")) {
        return "Ник должен начинаться с символа @"
    } else if (value.length < 6 || regexp.test(value.substring(1))) {
        return "Ник заполнен некорректно";
    }
    return "";
};

export const validateEmail = value => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (isEmpty(value)) {
        return "Почта не заполнена";
    } else {
        const isValid = regexp.test(value);
        return isValid ? "" : "Почта заполнена некорректно";
    }
};