import './scss/index.scss';
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import store from "./store";
import App from './components/App';

ReactDOM
    .createRoot(document.getElementById('root'))
    .render(
        <Provider store={store}>
            <App/>
        </Provider>,
    );