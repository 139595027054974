export const AppealReason = {
    FIRST: {
        id: 1,
        text: "Первичная онлайн встреча",
        price: 2000
    },
    SECOND: {
        id: 2,
        text: "Повторная онлайн встреча",
        price: 1500
    },
    VACCINE: {
        id: 3,
        text: "Составление индивидуального графика вакцинации",
        price: 1000
    }
}