import React from "react";
import {connect} from "react-redux";

class LoadingDialog extends React.Component {

    render() {
        if (!this.props.isLoadingDialogActive) {
            document.body.classList.remove("lock");
            return null;
        }
        document.body.classList.add("lock");

        return (
            <div className="dialog__shadow">
                <div className="spinner">
                    <div className="spinner__row">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            </div>
        );
    }
}

const initMapStateToProps = state => ({
    isLoadingDialogActive: state.app.isLoadingDialogActive
});

export default connect(
    initMapStateToProps
)(LoadingDialog);